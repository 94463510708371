import React, { useState } from "react";
import Div100vh from "react-div-100vh";
import { Container } from "react-bootstrap";
import "./index.css";
import Home from "./Home";
import Hours from "./Hours";
import Founders from "./Founders";
import Attractions from "./Attractions";
import South from "./South";
import Troy from "./Troy";
import { useWindowSize } from "../../_shared/utils";

const Whr = ({ close }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [selection, setSelection] = useState("home");
  const size = useWindowSize();

  const pages = {
    home: <Home />,
    hours: <Hours />,
    founders: <Founders />,
    attractions: <Attractions />,
    south: <South />,
    troy: <Troy />,
  };

  const menuItems = [
    {
      id: "home",
      display: "Home",
    },
    {
      id: "hours",
      display: "Hours/Pricing",
    },
    {
      id: "founders",
      display: "Our Founders",
    },
    {
      id: "attractions",
      display: "Attractions",
    },
    {
      id: "south",
      display: "South Ohio Regional Shakespeare Co.",
    },
    {
      id: "troy",
      display: "Daniel and Alice Troy Foundation",
    },
  ];

  return (
    <Div100vh
      dontresize="true"
      className="vh-100"
      style={{ backgroundColor: "white", overflow: "hidden" }}
    >
      <Container fluid className={"whr_container"}>
        <div className="wht-header d-flex flex-row justify-content-between">
          {size.width < 800 && (
            <div role="button" onClick={() => setMenuOpen(!menuOpen)}>
              <img
                style={{ marginTop: "24px", marginLeft: "14px" }}
                src="/13/whr/whr-hamburger-menu.svg"
                alt="menu"
              />
            </div>
          )}
          <div
            role="button"
            className="p-3"
            onClick={() => setSelection("home")}
          >
            <img src="/13/whr/whr-home-button.svg" alt="home button" />
          </div>
          <div role="button" className="p-3" onClick={() => close()}>
            <img
              style={{ width: "24px", height: "24px" }}
              src="/13/hak12-mediaplayer-close-button.svg"
              alt="close button"
            />
          </div>
        </div>
        {size.width > 800 && (
          <div className="pageHeader">
            <p>
              {menuItems.map((mi) => (
                <span
                  onClick={() => {
                    setSelection(mi.id);
                  }}
                  className="px-4"
                  style={
                    mi.id === selection
                      ? { cursor: "pointer", textDecoration: "underline" }
                      : { cursor: "pointer" }
                  }
                >
                  {mi.display}
                </span>
              ))}
            </p>
          </div>
        )}
        {size.width < 800 && (
          <div className="pageHeader">
            <p>{menuItems.find((mi) => mi.id === selection).display}</p>
          </div>
        )}
        {menuOpen && size.width < 800 && (
          <>
            <div className="menuContainer">
              <div>
                {menuItems.map((m) => (
                  <span key={m.id}>
                    <p
                      role="button"
                      onClick={() => {
                        setSelection(m.id);
                        setMenuOpen(!menuOpen);
                      }}
                      className="text-center"
                    >
                      {m.display}
                    </p>
                    <hr></hr>
                  </span>
                ))}
              </div>
            </div>
          </>
        )}
        <div className="whr-content">{pages[selection]}</div>
        <div className="footer">
          <p className="text-center p-0 m-0">White Hilt Renaissance Faire</p>
          <p className="text-center p-0 m-0">673 Dragon Breath Rd.</p>
          <p className="text-center p-0 m-0">Tall Oaks, OH</p>
        </div>
      </Container>
    </Div100vh>
  );
};

export default Whr;
